import React, { FC } from 'react'
import { BreadCrumb } from 'components/block/BreadCrumbs/breadCrumbs.types'
import { BreadCrumbItem } from 'components/block/BreadCrumbs/BreadCrumbItem'
import { FontSize } from 'constants/sizes'
import { styled } from 'linaria/react'

/**
 * Два компонента обертки
 * src/common/components/page/list/Title/Title.tsx - для списка
 * src/common/components/page/announce/Title/Title.tsx - для конкретного объявления.
 * Формируют links и передают его в текущий компонент
 */
export const BreadCrumbs: FC<{
  breadCrumbs: BreadCrumb[]
  lastLinkExist?: boolean
}> = ({ breadCrumbs = [], lastLinkExist = false }) => {
  const list = breadCrumbs.map((item, index) => ({
    ...item,
    noLink: breadCrumbs.length - 1 === index && !lastLinkExist,
  }))

  const _list = list.filter(({ text }) => Boolean(text))

  const shouldUseSeoTags = _list.some(({ noLink }) => !noLink)

  return (
    <BreadCrumbList
      {...(shouldUseSeoTags && {
        itemScope: true,
        itemType: 'https://schema.org/BreadcrumbList',
      })}
    >
      {_list.map(({ text, url, noLink }, index) => (
        <BreadCrumbItem
          length={_list.length}
          index={index}
          url={url}
          text={text}
          lastLinkExist={lastLinkExist}
          key={`${url}-${index}`}
          noLink={noLink}
        />
      ))}
    </BreadCrumbList>
  )
}

const BreadCrumbList = styled.ol`
  list-style: none;
  margin: 5px 0 0 0;
  padding: 0;
  font-weight: normal;
  line-height: unset;
  font-size: ${FontSize.breadCrumbs}px;
  display: flex;
  flex-wrap: wrap;
`
