import { BreadCrumb } from 'components/block/BreadCrumbs/breadCrumbs.types'
import { IntlShape, defineMessages, useIntl } from 'react-intl'
import { createUrlUseParameters } from 'functions/createUrlUseParameters'
import { definitions } from 'api/generated/masked_ads'
import { maximumAge, minimumAge } from 'constants/age'
import { serializeTags } from 'functions/serializeTags'
import { useLocale } from 'hooks/useLocale'
import { useMemo } from 'react'

const messages: Record<string, Record<string, string>> = defineMessages({
  ageRange: {
    id: 'ageRangeBreadCrumb',
    defaultMessage:
      'от {ageFrom} - до {ageTo} {ageTo, plural, one {года} few {лет} many {лет} other {лет}}',
  },

  ageNotRange: {
    id: 'ageNotRangeBreadCrumb',
    defaultMessage:
      'в возрасте {ageFrom} {ageFrom, plural, one {года} few {лет} many {лет} other {лет}}',
  },

  breadCrumbs_1_1: {
    id: 'breadCrumbs.1.1',
    defaultMessage: 'Парень ищет парня',
  },

  breadCrumbs_1_2: {
    id: 'breadCrumbs.1.2',
    defaultMessage: 'Парень ищет девушку',
  },

  breadCrumbs_2_1: {
    id: 'breadCrumbs.2.1',
    defaultMessage: 'Девушка ищет парня',
  },

  breadCrumbs_2_2: {
    id: 'breadCrumbs.2.2',
    defaultMessage: 'Девушка ищет девушку',
  },

  breadCrumbs_3_1: {
    id: 'breadCrumbs.1.3',
    defaultMessage: 'Знакомства с парами',
  },

  breadCrumbs_3_2: {
    id: 'breadCrumbs.1.3',
    defaultMessage: 'Знакомства с парами',
  },

  breadCrumbs_1_3: {
    id: 'breadCrumbs.1.3',
    defaultMessage: 'Знакомства с парами',
  },

  breadCrumbs_2_3: {
    id: 'breadCrumbs.1.3',
    defaultMessage: 'Знакомства с парами',
  },
})

export const useBreadCrumbs = (
  gender1: number,
  gender2: number,
  tags: definitions['AdTag'][],
  /** кейс для страницы объявления у которого нет тегов и надо проставить теги в ссылки */
  additionalTags: definitions['AdTag'][],
  ageFrom?: number,
  ageTo?: number,
  location?: definitions['NamedLocation']
): BreadCrumb[] => {
  const intl = useIntl()
  const locale = useLocale()
  const _tags = additionalTags.sort((first, second) => first.id - second.id)

  return useMemo(() => {
    const searchGender = intl.formatMessage(
      messages[`breadCrumbs_${gender1}_${gender2}`]
    )

    const ageRangeText = createAgeRange(intl, ageFrom, ageTo)

    const country = createBreadCrumb(
      locale,
      `${location?.country}-0-0-0`,
      `${searchGender} ${location?.countryName}`,
      gender1,
      gender2,
      _tags
    )

    const region = createBreadCrumb(
      locale,
      `${location?.country}-${location?.region}-0-0`,
      `${location?.regionName}`,
      gender1,
      gender2,
      _tags
    )

    const city = createBreadCrumb(
      locale,
      `${location?.country}-${location?.region}-${location?.city}-0`,
      `${location?.cityName}`,
      gender1,
      gender2,
      _tags
    )

    const tag = tags[0] ?? ''

    const target = createBreadCrumb(
      locale,
      `${location?.country}-${location?.region}-${location?.city}-0`,
      tag.title,
      gender1,
      gender2,
      _tags
    )

    const ageRange = ageRangeText
      ? createBreadCrumb(
          locale,
          `${location?.country}-${location?.region}-${location?.city}-0`,
          ageRangeText,
          gender1,
          gender2,
          _tags,
          ageFrom,
          ageTo
        )
      : null

    if (location?.city) {
      if (target) {
        if (ageRange) {
          return [country, region, city, target, ageRange]
        }

        return [country, region, city, target]
      }

      if (ageRange) {
        return [country, region, city, ageRange]
      }

      return [country, region, city]
    }

    if (location?.region) {
      if (target) {
        if (ageRange) {
          return [country, region, target, ageRange]
        }

        return [country, region, target]
      }

      if (ageRange) {
        return [country, region, ageRange]
      }

      return [country, region]
    }

    if (target) {
      if (ageRange) {
        return [country, target, ageRange]
      }

      return [country, target]
    }

    if (ageRange) {
      return [country, ageRange]
    }

    return [country]
  }, [_tags, ageFrom, ageTo, gender1, gender2, intl, locale, location, tags])
}

const createBreadCrumb = (
  locale: string,
  locationId: string,
  text: string,
  gender1: number,
  gender2: number,
  tags: definitions['AdTag'][],
  ageFrom: number = minimumAge,
  ageTo: number = maximumAge
): BreadCrumb => {
  return {
    url: createUrlUseParameters(
      locale,
      gender1,
      gender2,
      ageFrom,
      ageTo,
      serializeTags(tags),
      locationId
    ),
    text,
  }
}

const createAgeRange = (
  intl: IntlShape,
  ageFrom?: number,
  ageTo?: number
): string | null => {
  if (ageFrom === minimumAge && ageTo === maximumAge) {
    return null
  }

  if (ageFrom === ageTo) {
    return intl.formatMessage(messages.ageNotRange, {
      ageFrom,
    })
  }

  return intl.formatMessage(messages.ageRange, {
    ageFrom,
    ageTo,
  })
}
