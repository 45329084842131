import { definitions } from 'api/generated/masked_ads'

export type FieldValueTypes =
  | string
  | boolean
  | number
  | Record<string, number | string>
  | definitions['AdTag'][]

export enum FieldName {
  whoAmI = 'whoAmI',
  lookingFor = 'lookingFor',
  datingGoal = 'datingGoal',
  location = 'location',
  ageFrom = 'ageFrom',
  ageTo = 'ageTo',
  answerText = 'answerText',
  answerEmail = 'answerEmail',
  addText = 'text',
  addEmail = 'email',
  birthDate = 'birthDate',
  isPaid = 'isPaid',
  sms = 'sms',

  // new fields
  whoIsSeeker = 'whoIsSeeker',
  whoLookingFor = 'whoLookingFor',
  minAge = 'minAge',
  maxAge = 'maxAge',
  tags = 'tags',
}
